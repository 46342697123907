import React from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import TradingViewWidget from "react-tradingview-widget";
import { getData, getIndexesForModel, getModels, getConfidenceData, getPredictionChartData, getSentimentAnalysis, changeModel, getLiveIndexes } from "../serives/backendService";
import Select from "react-select";
import LineChart from "../charts/LineChart01";
import { tailwindConfig, hexToRGB, formatValue } from "../utils/Utils";
import NumberFormat from "react-number-format";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import LiquidFillGauge from "react-liquid-gauge";
import { FaArrowRight, FaArrowLeft, FaAdjust, FaLaptopHouse, FaCoins, FaChartLine, FaArrowAltCircleDown, FaArrowAltCircleUp, FaPauseCircle } from "react-icons/fa";
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import "chartjs-adapter-moment";
import GaugeChart from "react-gauge-chart";


function linearRegression(y, x) {
  var lr = {};
  var n = y.length;
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var sum_yy = 0;

  for (var i = 0; i < y.length; i++) {
    sum_x += x[i];
    sum_y += y[i];
    sum_xy += x[i] * y[i];
    sum_xx += x[i] * x[i];
    sum_yy += y[i] * y[i];
  }

  lr["slope"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  lr["intercept"] = (sum_y - lr.slope * sum_x) / n;
  lr["r2"] = Math.pow((n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)), 2);

  return lr;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 4,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const styleSentiment = {
  textAlign: "center",
  fontWeight: "bold",
  // color: "#2962ff",
  fontSize: "24px",
  fontFamily: "Trebuchet MS,roboto,ubuntu,sans-serif",
};

const indicatorStyle = {
  textAlign: "center",
  alignSelf: "center",
  width: "200px",
  marginTop: "10px",
  padding: "7px",
  border: "1px #00000091 solid",
};

const chartStyle = {
  // height: 0,
};

const textHeader = {
  textAlign: "center",
  fontWeight: 400,

  fontSize: "20px",
  fontFamily: "Trebuchet MS,roboto,ubuntu,sans-serif",
  marginBottom: "15px",
};

const textIndex = {
  fontWeight: 400,
  // fontWeight: "bold",
  // color: "#2962ff",
  fontSize: "20px",
  fontFamily: "Trebuchet MS,roboto,ubuntu,sans-serif",
};

const options = { style: "currency", currency: "USD", minimumFractionDigits: 4 };
const numberFormat = new Intl.NumberFormat("en-US", options);

const getConfig = (labels, actualResults, predictions) => ({
  labels: labels,
  datasets: [
    // Indigo line
    {
      data: actualResults,
      fill: true,
      backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
      borderColor: tailwindConfig().theme.colors.indigo[500],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      clip: 20,
    },
    // Gray line
    {
      data: predictions,
      borderColor: tailwindConfig().theme.colors.gray[300],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
      clip: 20,
    },
  ],
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.timerUpdate = null;

    this.startColor = "#6495ed"; // cornflowerblue
    this.endColor = "#dc143c"; // crimson

    this.state = { indexValues: [], highLowInd: "LOW", lowVal: 0, hiVal: 0, buyStatus: "sell", lastPred: "", confidenceLevel: 0, lastPredDate: "", isEnbled: true, model_options_select_default: null, options_select_default: null, isOnline: null, options_select: [], model_options_select: [], indexName: "BTCTUSD", indexNameFull: "", refresh: false, labels: [], actualResults: [], predictions: [], changepct: 0, value: 0, sentiment: 0, react: "UNKNOWN", negative: 0, positive: 0, neutral: 0 };
    console.log(this.state.data);
    this.change = this.change.bind(this);
    this.update = this.update.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.checkBuyStatus = this.checkBuyStatus.bind(this);
    // this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  changeModel(event) {
    this.setState({ ...this.state, isEnbled: false });
    changeModel(event.value)
      .then(() => {
        getIndexesForModel(event.value)
          .then((res) => {
            this.setState({ ...this.state, options_select: res, model_options_select_default: event });
          })
          .then(() => {
            this.change(this.state.options_select[0]);
          })
          .then(() => {
            getLiveIndexes(event.value)
              .then((res) => {
                this.setState({ ...this.state, indexValues: res.table, isEnbled: true });
              })
              .catch((error) => {
                console.log(error);
                this.setState({ ...this.state, indexValues: [], isEnbled: true });
              });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ ...this.state, isEnbled: true });
          });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ ...this.state, isEnbled: true });
      });
  }

  update() {
    changeModel(this.state.model_options_select_default.value)
      .then((res) => {
        this.setState({ ...this.state, lastPred: res.update, lastPredDate: this.state.labels[0] });
      })
      .then(() => {
        getPredictionChartData(this.state.options_select_default.value, this.state.model_options_select_default.value)
          .then((res) => {
            this.setState({ ...this.state, labels: res.labels, actualResults: res.accLine, predictions: res.predLine, changepct: res.changepct.toFixed(2), value: formatter.format(res.value.toFixed(4)) });
          })
          .then(() => {
            getSentimentAnalysis(this.state.options_select_default.value, this.state.model_options_select_default.value)
              .then((res) => {
                var react = "STRONG SELL";
                switch (true) {
                  case res.sentimentValue <= 0.2:
                    react = "STRONG SELL";
                    break;
                  case res.sentimentValue > 0.2 && res.sentimentValue <= 0.4:
                    react = "SELL";
                    break;
                  case res.sentimentValue > 0.4 && res.sentimentValue <= 0.6:
                    react = "NEUTRAL";
                    break;
                  case res.sentimentValue > 0.6 && res.sentimentValue <= 0.8:
                    react = "BUY";
                    break;
                  case res.sentimentValue > 0.8 && res.sentimentValue <= 1:
                    react = "STRONG BUY";
                    break;
                  default:
                    break;
                }
                this.setState({ ...this.state, sentiment: res.sentimentValue, react: react, negative: res.negative, positive: res.positive, neutral: res.neutral });
              })
              .then(() =>
                getConfidenceData(this.state.options_select_default.value, this.state.model_options_select_default.value)
                  .then((res) => {
                    this.checkBuyStatus(res.confidence, this.state.predictions);
                    this.setState({ ...this.state, confidenceLevel: res.confidence });
                  })
                  .catch((error) => {
                    console.log(error);
                  })
              )
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkBuyStatus(confidence, predictions) {
    var known_y = [1, 2, 3, 4, 5];
    var known_x = predictions.slice(Math.max(predictions.length - 5, 1));
    var lr = linearRegression(known_y, known_x);
    const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
    const avgVals = average(known_x);
    // var known_xcurrent = predictions.slice(Math.max(predictions.length - 5 + 1, 1));
    var minVal = Math.min(...known_x);
    var maxVal = Math.max(...known_x);
    console.log(minVal, maxVal);
    var pct = 1 - avgVals / predictions[9];
    pct = Math.abs(pct) * 100;
    console.log(pct);
    var strValue = pct > 5 ? "HIGH" : "LOW";

    if (confidence > 75) {
      if (Math.sign(lr.slope) > 0) {
        this.setState({ ...this.state, buyStatus: "buy", highLowInd: strValue, lowVal: minVal, hiVal: maxVal });
      }
      if (Math.sign(lr.slope) < 0) {
        this.setState({ ...this.state, buyStatus: "sell", highLowInd: strValue, lowVal: minVal, hiVal: maxVal });
      }
      if (Math.sign(lr.slope) == 0) {
        this.setState({ ...this.state, buyStatus: "noaction", highLowInd: strValue, lowVal: minVal, hiVal: maxVal });
      }
    } else {
      this.setState({ ...this.state, buyStatus: "noaction", highLowInd: strValue, lowVal: minVal, hiVal: maxVal });
    }
    console.log(lr.slope, lr.r2);
  }

  change(event) {
    this.setState({ ...this.state, indexName: event.short, indexNameFull: event.value, refresh: true, options_select_default: event });
    getPredictionChartData(event.value, this.state.model_options_select_default.value)
      .then((res) => {
        console.log(res);
        this.setState({ ...this.state, labels: res.labels, actualResults: res.accLine, predictions: res.predLine, changepct: res.changepct.toFixed(2), value: formatter.format(res.value.toFixed(4)) });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ ...this.state, labels: [], actualResults: [], predictions: [], changepct: [], value: "" });
      })
      .then(() => {
        getSentimentAnalysis(event.value, this.state.model_options_select_default.value)
          .then((res) => {
            var react = "STRONG SELL";
            switch (true) {
              case res.sentimentValue <= 0.2:
                react = "STRONG SELL";
                break;
              case res.sentimentValue > 0.2 && res.sentimentValue <= 0.4:
                react = "SELL";
                break;
              case res.sentimentValue > 0.4 && res.sentimentValue <= 0.6:
                react = "NEUTRAL";
                break;
              case res.sentimentValue > 0.6 && res.sentimentValue <= 0.8:
                react = "BUY";
                break;
              case res.sentimentValue > 0.8 && res.sentimentValue <= 1:
                react = "STRONG BUY";
                break;
              default:
                break;
            }
            this.setState({ ...this.state, sentiment: res.sentimentValue, react: react, negative: res.negative, positive: res.positive, neutral: res.neutral });
          })
          .then(() =>
            getConfidenceData(this.state.options_select_default.value, this.state.model_options_select_default.value)
              .then((res) => {
                this.checkBuyStatus(res.confidence, this.state.predictions);
                this.setState({ ...this.state, confidenceLevel: res.confidence });
              })
              .catch((error) => {
                console.log(error);
              })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.setState({ ...this.state, refresh: false });
      console.log(this.state.indexName);
    }, 1500);
  }

  componentWillUnmount() {
    if (this.timerUpdate != null) clearInterval(this.timerUpdate);
  }
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  componentDidMount() {
    getModels()
      .then((res) => {
        console.log(res.models[0]);
        this.setState({ ...this.state, model_options_select: res.models, model_options_select_default: res.models[0] });
      })
      .then(() => {
        getIndexesForModel(this.state.model_options_select_default.value)
          .then((res) => {
            this.setState({ ...this.state, options_select: res, options_select_default: res[0] });
          })
          .then(() => {
            getPredictionChartData(this.state.options_select_default.value, this.state.model_options_select_default.value)
              .then((res) => {
                this.setState({ ...this.state, labels: res.labels, lastPredDate: res.labels[0], actualResults: res.accLine, predictions: res.predLine, changepct: res.changepct.toFixed(2), value: formatter.format(res.value.toFixed(4)) });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .then(() => {
            getSentimentAnalysis(this.state.options_select_default.value, this.state.model_options_select_default.value)
              .then((res) => {
                // console.log(res)
                var react = "STRONG SELL";
                switch (true) {
                  case res.sentimentValue <= 0.2:
                    react = "STRONG SELL";
                    break;
                  case res.sentimentValue > 0.2 && res.sentimentValue <= 0.4:
                    react = "SELL";
                    break;
                  case res.sentimentValue > 0.4 && res.sentimentValue <= 0.6:
                    react = "NEUTRAL";
                    break;
                  case res.sentimentValue > 0.6 && res.sentimentValue <= 0.8:
                    react = "BUY";
                    break;
                  case res.sentimentValue > 0.8 && res.sentimentValue <= 1:
                    react = "STRONG BUY";
                    break;
                  default:
                    break;
                }
                this.setState({ ...this.state, sentiment: res.sentimentValue, react: react, negative: res.negative, positive: res.positive, neutral: res.neutral });
              })
              .then(() =>
                getConfidenceData(this.state.options_select_default.value, this.state.model_options_select_default.value)
                  .then((res) => {
                    this.checkBuyStatus(res.confidence, this.state.predictions);
                    this.setState({ ...this.state, confidenceLevel: res.confidence });
                  })
                  .catch((error) => {
                    console.log(error);
                  })
              )
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .then(() => {
        changeModel(this.state.model_options_select_default.value)
          .then((res) => {
            this.setState({ ...this.state, lastPred: res.update });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .then(() => {
        getLiveIndexes(this.state.model_options_select_default.value)
          .then((res) => {
            console.log(res);
            this.setState({ ...this.state, indexValues: res.table });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .then(() => {
        this.timerUpdate = setInterval(() => {
          this.update();
        }, 350000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { labels, actualResults, predictions } = this.state;
    const chartConfig = getConfig(labels, actualResults, predictions);
    const radius = 150;
    const interpolate = interpolateRgb("red", "lime");
    const fillColor = interpolate(this.state.confidenceLevel / 100);
    const gradientStops = [
      {
        key: "0%",
        stopColor: color(fillColor).darker(0.5).toString(),
        stopOpacity: 1,
        offset: "0%",
      },
      {
        key: "50%",
        stopColor: fillColor,
        stopOpacity: 0.75,
        offset: "50%",
      },
      {
        key: "100%",
        stopColor: color(fillColor).brighter(0.5).toString(),
        stopOpacity: 0.5,
        offset: "100%",
      },
    ];

    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header />
          {/* <Header /> */}

          <main>
          <div class="mx-auto">
            <div class="py-8 px-2 bg-white">
              <div class="px-4 mx-auto"> 
                <h2 class="text-2xl font-bold">
                  <span>
                    <FaLaptopHouse class="inline mr-4 mb-1"></FaLaptopHouse>
                    Dashboard
                  </span>
                </h2>
              </div>
            </div>

            <section class="py-8">
              <div class="container px-4 mx-auto">
                {/* Dashboard actions */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Avatars */}
                  {/* <DashboardAvatars /> */}
                  <div style={{ width: "300px" }} disabled={!this.state.isEnbled}>
                    Model
                    <Select width="400px" value={this.state.model_options_select_default} style={{ width: "600px" }} onChange={this.changeModel} options={this.state.model_options_select} />
                  </div>
                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-end sm:justify-end gap-2">

                    <div style={{ width: "300px" }} disabled={!this.state.isEnbled}>
                      Index
                      <Select width="400px" value={this.state.options_select_default} style={{ width: "600px" }} onChange={this.change} options={this.state.options_select} />
                    </div>
                   
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-6">
                  <div  className="flex flex-col col-span-full w-full p-2 sm:col-span-12 xl:col-span-12 bg-blue-700 shadow-lg rounded-sm text-white mb-2 rounded-2xl bg-blend-color bg-white p-4">
                    <div>Last prediction made on {this.state.lastPred}</div>
                  </div>
                </div>

                {this.state.labels[0] === this.state.lastPredDate ? (
                  <></>
                ) : (
                  <div className="grid grid-cols-12 gap-6">
                    <div style={{ width: "100%", padding: "10px", background: "yellow", color: "black" }} className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
                      <div>Prediction has been updated !</div>
                    </div>
                  </div>
                )}

                {/* Cards */}
                <div className="grid grid-cols-12 gap-6">
                  <div style={{ width: "100%" }} className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
                    <div style={{ position: "sticky" }}>
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 99999999,
                          bottom: "30px",
                          width: "100px",
                          height: "40px",
                          background: "white",
                          marginLeft: "1px",
                          borderRadius: "80px",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {this.state.indexName}
                      </div>
			
                      <TradingViewWidget interval="60" timezone={"Europe/London"} save_image={false} width="100%" style={{ width: "100%" }} symbol="XAU=" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="py-8 px-2 bg-white">
              <div class="container px-4 mx-auto">
                <h2 class="text-2xl font-bold">
                  <span>
                    <FaCoins class="inline mr-4 mb-1"></FaCoins>
                    Indicators
                  </span>
                </h2>
              </div>
            </div>

            <section class="py-8">
              <div class="container px-4 mx-auto">
                    <div class="flex flex-wrap -m-4">
                      <div class="w-full  p-4 ">
                        <div class="p-1 rounded-2xl bg-blend-color bg-white p-4">
                          <div class="flex flex-wrap -m-4">
                            <div class="w-full lg:w-1/3 p-4 ">
                             <span class="mr-4">Recomendation: </span>
                              <span class={`inline-block py-2 px-4 text-white ${this.state.buyStatus == "buy" ? "bg-green-500" : (this.state.buyStatus == "sell" ? "bg-red-500": "bg-gray-500")}  text-center rounded-full p-4`}>
                                {this.state.buyStatus == "buy" ? (
                                  <span>
                                    <FaArrowAltCircleUp class="inline mr-4"></FaArrowAltCircleUp>BUY
                                  </span>
                                ) :
                                ( this.state.buyStatus == "sell" ? (
                                  <span>
                                    <FaArrowAltCircleDown class="inline mr-4"></FaArrowAltCircleDown>SELL
                                  </span>
                                ) : (<span>
                                    <FaPauseCircle class="inline mr-4"></FaPauseCircle>NO ACTION
                                  </span>)
                                  )}
                              </span>
                            </div>
                            <div class="w-full lg:w-1/3 p-4 ">
                            <span class="mr-4">Low: </span>
                               <span class={`inline-block py-2 px-4 text-white bg-blue-700  text-center rounded-full p-4`}>
                                  <span>
                                  <NumberFormat value={this.state.lowVal.toFixed(4)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div> Available: {formattedValue}</div>} />
                                  </span>
                              </span>
                            </div>
                            <div class="w-full lg:w-1/3 p-4 ">
                            <span class="mr-4">High: </span>
                               <span class={`inline-block py-2 px-4 text-white bg-yellow-700  text-center rounded-full p-4`}>
                                  <span>
                                  <NumberFormat value={this.state.hiVal.toFixed(4)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div> Available: {formattedValue}</div>} />
                                  </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


              </div>
            </section>

            <div class="py-8 px-2 bg-white">
              <div class="container px-4 mx-auto">
                <h2 class="text-2xl font-bold">
                  <span>
                    <FaChartLine class="inline mr-4 mb-1"></FaChartLine>
                    Predictions
                  </span>
                </h2>
              </div>
            </div>

            <section class="py-8">
              <div class="container px-4 mx-auto">
                <div class="flex flex-wrap -m-4">
                  <div class="w-full lg:w-1/3 p-4 ">
                    <div class="p-1 rounded-2xl bg-blend-color bg-white">
                      <div className="px-5 pt-5">
                        <header className="flex justify-between items-start mb-2">
                          {/* Icon */}
                          {/* <img src={Icon} width="32" height="32" alt="Icon 01" /> */}
                          {/* Menu button */}
                        </header>
                        <h2 className="text-lg font-semibold text-gray-800 mb-2" style={textHeader}>
                          Prediction for {this.state.indexName}
                        </h2>
                        <div className="text-xs font-semibold text-gray-400 uppercase mb-1"></div>
                        <div className="flex items-start">
                          <div className="text-3xl font-bold text-gray-800 mr-2">{this.state.value}</div>
                          <div className={`text-sm font-semibold text-white px-1.5 rounded-full ${this.state.changepct < 0 ? "bg-red-500" : "bg-green-500"}`}>{this.state.changepct}%</div>
                          {/* <div style={{ marginLeft: "20px" }}>+1h</div> */}
                        </div>
                      </div>
                      {/* Chart built with Chart.js 3 */}
                      <div className="flex-grow h-96">
                        {/* Change the height attribute to adjust the chart height */}
                        <LineChart data={chartConfig} width={389} height={128} />
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:w-1/3 p-4 ">
                    <div class="p-2 rounded-2xl bg-blend-color bg-white">
                      <div style={{ width: "100%" }} className="">
                        <div className="px-5 pt-5">
                          <header className="flex justify-between items-start mb-2"></header>
                          <h2 className="text-lg font-semibold text-gray-800 mb-2" style={textHeader}>
                            Confidence Level
                          </h2>
                          <LiquidFillGauge
                            style={{ margin: "0 auto", height: "375px", marginTop: "60px" }}
                            width={radius * 2}
                            height={radius * 2}
                            value={this.state.confidenceLevel}
                            percent="%"
                            textSize={1}
                            textOffsetX={0}
                            textOffsetY={0}
                            textRenderer={(props) => {
                              const value = Math.round(props.value);
                              const radius = Math.min(400 / 2, props.width / 2);
                              const textPixels = (props.textSize * radius) / 2;
                              const valueStyle = {
                                fontSize: textPixels,
                              };
                              const percentStyle = {
                                fontSize: textPixels * 0.6,
                              };

                              return (
                                <tspan>
                                  <tspan className="value" style={valueStyle}>
                                    {value}
                                  </tspan>
                                  <tspan style={percentStyle}>{props.percent}</tspan>
                                </tspan>
                              );
                            }}
                            riseAnimation
                            waveAnimation
                            waveFrequency={2}
                            waveAmplitude={1}
                            gradient
                            gradientStops={gradientStops}
                            circleStyle={{
                              fill: fillColor,
                            }}
                            waveStyle={{
                              fill: fillColor,
                            }}
                            textStyle={{
                              fill: color("#444").toString(),
                              fontFamily: "Arial",
                            }}
                            waveTextStyle={{
                              fill: color("#fff").toString(),
                              fontFamily: "Arial",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:w-1/3 p-4 ">
                    <div class="p-1 rounded-2xl bg-blend-color bg-white">
                      <div style={{ width: "100%", height: "480px" }} className="mt-4">
                        <div className="overflow-x-auto">
                          <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs uppercase text-gray-800 bg-white-50 rounded-sm">
                              <tr>
                                <th className="p-2">
                                  <div className="font-semibold text-left">Index</div>
                                </th>
                                <th className="p-2">
                                  <div className="font-semibold text-center">Current</div>
                                </th>
                                <th className="p-2">
                                  <div className="font-semibold text-center">Predicted</div>
                                </th>
                                <th className="p-2">
                                  <div className="font-semibold text-center">Change</div>
                                </th>
                              </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm font-medium divide-y divide-gray-100">
                              {/* Row */}
                              {this.state.indexValues.map((val) => (
                                <tr>
                                  <td className="p-2">
                                    <div className="flex items-center">
                                      <div className="text-gray-600" style={{ fontWeight: "900" }}>
                                        {val.fullName}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-center ">{numberFormat.format(val.currentVal)}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-center ">{numberFormat.format(val.nextPreictionValue)}</div>
                                  </td>
                                  <td className="p-2">
                                    <div style={{ textAlign: "center" }} className={`text-sm font-semibold text-white px-1.5 rounded-full ${val.nextPredictionPct < 0 ? "bg-red-500" : "bg-green-500"}`}>
                                      {val.nextPredictionPct}%
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            </div>
          </main>

          <Banner />
        </div>
      </div>
    );
  }
}

export default Dashboard;
