import React from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import TradingViewWidget from "react-tradingview-widget";
import {
  getData, getIndexesForModel, getModels, getConfidenceData, getPredictionChartData, getSentimentAnalysis, changeModel,
  getLiveIndexes, getUseModelData, getCurrentModelData, postCurrentModelData, getRLLive, getRLInputData, getLiveSentiment, getTweets, getNews, getLiveSentimentNews, getCurrentAsset
} from "../serives/backendService";
import Select from "react-select";
import LineChart from "../charts/LineChart01";
import { tailwindConfig, hexToRGB, formatValue } from "../utils/Utils";
import NumberFormat from "react-number-format";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import LiquidFillGauge from "react-liquid-gauge";
import { FaArrowRight, FaArrowLeft, FaAdjust, FaLaptopHouse, FaCoins, FaChartLine, FaArrowAltCircleDown, FaArrowAltCircleUp, FaPauseCircle } from "react-icons/fa";
// import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import "chartjs-adapter-moment";
import GaugeChart from "react-gauge-chart";
import * as tw from "lightweight-charts-react-wrapper";
import TransactionHistory from "./TransactionHistory";
import { symbol } from "prop-types";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 4,
});

const styleSentiment = {
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "24px",
  fontFamily: "Trebuchet MS,roboto,ubuntu,sans-serif",
};

const indicatorStyle = {
  textAlign: "center",
  alignSelf: "center",
  width: "200px",
  marginTop: "10px",
  padding: "7px",
  border: "1px #00000091 solid",
};

const textHeader = {
  textAlign: "center",
  fontWeight: 400,

  fontSize: "20px",
  fontFamily: "Trebuchet MS,roboto,ubuntu,sans-serif",
  marginBottom: "15px",
};

const textIndex = {
  fontWeight: 400,
  fontSize: "20px",
  fontFamily: "Trebuchet MS,roboto,ubuntu,sans-serif",
};

const options = { style: "currency", currency: "USD", minimumFractionDigits: 4 };
const numberFormat = new Intl.NumberFormat("en-US", options);

const getConfig = (labels, actualResults, predictions) => ({
  labels: labels,
  datasets: [
    // Indigo line
    {
      data: actualResults,
      fill: true,
      backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
      borderColor: tailwindConfig().theme.colors.indigo[500],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      clip: 20,
    },
    // Gray line
    {
      data: predictions,
      borderColor: tailwindConfig().theme.colors.gray[300],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
      clip: 20,
    },
  ],
});

class RLBotLive extends React.Component {
  constructor(props) {
    super(props);
    this.timerUpdate = null;
    this.series1 = React.createRef();
    this.chart1 = React.createRef();
    this.startColor = "#6495ed"; // cornflowerblue
    this.endColor = "#dc143c"; // crimson
    this.checkModelInterval = null
    this.lastUpdateHour = new Date().getHours()
    this.setSidebarOpen = this.setSidebarOpen.bind(this)

    this.state = {
      indexValues: [], tweets: [],
      highLowInd: "LOW", lowVal: 0, hiVal: 0, buyStatus: "sell", lastPred: "", confidenceLevel: 0, lastPredDate: "",
      isEnbled: true, model_options_select_default: null, options_select_default: null, isOnline: null, options_select: [],
      model_options_select: [], indexName: "BTCUSDT", indexNameFull: "", refresh: false, labels: [], actualResults: [],
      predictions: [], changepct: 0, value: 0, sentiment: 0, react: "UNKNOWN", negative: 0, positive: 0, neutral: 0,
      rlModelDetals: {},
      rlCurrentAsset: {},
      botRun: false,
      lastUpdate: null,
      currentAction: "",
      lastActionDate: "",
      lastAction: "",
      news: [],
      sidebarOpen: false,

      chart: {
        localization: {

        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
        layout: {
          backgroundColor: '#2B2B43',
          lineColor: '#2B2B43',
          textColor: '#D9D9D9',
        },
        watermark: {
          color: 'rgba(0, 0, 0, 0)',
        },
        crosshair: {
          color: '#758696',
        },
        grid: {
          vertLines: {
            color: '#2B2B43',
          },
          horzLines: {
            color: '#363C4E',
          },
        },
      },
      data: [],
      markers: [

      ],
      dataBuy: [],
      dataSell: [],
      pairs: [],
      inputPairs: [],
      actualResults: [],
      labels: [],
      actualResultsNews: [],
      labelsNews: [],
      symbol: ""
    };

    this.change = this.change.bind(this);
    this.update = this.update.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.checkBuyStatus = this.checkBuyStatus.bind(this);
    this.runModel = this.runModel.bind(this)
    this.loadModel = this.loadModel.bind(this)
    this.addMarkers = this.addMarkers.bind(this)
    this.getConfig = this.getConfig.bind(this)
  }

  getConfig(idx) {
    return {
      labels: this.state.pairs[idx]?.timeArr,
      datasets: [
        {
          data: this.state.pairs[idx]?.valArr,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          clip: 20,
        }
      ],
    }
  };

  getConfigTwitter() {
    return {
      labels: this.state.labels,
      datasets: [
        {
          data: this.state.actualResults,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          clip: 20,
        }
      ],
    }
  };

  getConfigNews() {
    return {
      labels: this.state.labelsNews,
      datasets: [
        {
          data: this.state.actualResultsNews,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          clip: 20,
        }
      ],
    }
  };

  loadModel() {
    getUseModelData().then(res => {
      this.setState({ ...this.state, rlModelDetals: res, indexNameFull: res.model_options_select_default['label'], indexName: res.model_options_select_default['label'].replace('-', ''), symbol: res.display })
      postCurrentModelData(res).then().catch((error) => { });
    }).catch((error) => { });
  }

  clearMarkersAndData() {
    try {
      this.series1.current.setMarkers([])
      this.setState({
        ...this.state, pairs: [],
        inputPairs: []
      })
    }
    catch { }
  }

  setSidebarOpen(statusLocal) {
    console.log("Executed!!!")
    console.log(this.state.sidebarOpen)
    this.setState({...this.state, sidebarOpen: statusLocal})
  }


  addMarkers(res) {
    var m = []
    var data = res['data'].map(x => ({ 'time': x.time, 'value': x.value }))
    for (let i = 0; i < res["states_buy"].length; i++) {
      var id = res["states_buy"][i]
      m.push(
        {

          time: data[id].time,
          //  + 3600,
          position: 'belowBar',
          color: 'lime',
          shape: 'arrowUp',
          text: 'Buy @ ' + data[id].value,
        }
      )
    }
    for (let i = 0; i < res["states_sell"].length; i++) {
      var id = res["states_sell"][i]
      m.push(
        {

          time: data[id].time,
          //  + 3600,
          position: 'aboveBar',
          color: '#e91e63',
          shape: 'arrowDown',
          text: 'Sell @ ' + data[id].value,
        }
      )
    }
    m = m.sort(function (x, y) {
      return x.time - y.time;
    })
    this.series1.current.setMarkers(m)

  }

  addHours(date, hours) {
    date.setHours(date.getHours() + hours);

    return date;
  }

  rlModelUpdate(hours) {
    if (this.state.botRun === true) {
      this.lastUpdateHour = hours
      getRLLive().then(res => {
        var currentAction = ""
        if (res.currentActionActiv)
          currentAction = res.lastAction[0]

        this.setState({
          ...this.state, currentAction: currentAction, lastUpdate: new Date(res.lastPoint['time'] * 1000).toLocaleString(), executeHour: new Date(res.lastPoint['time'] * 1000 + 3600000).toLocaleString(),
          lastActionDate: new Date(res.previousActioPoint['time'] * 1000 + 3600000).toLocaleString(), lastAction: res.previousAction[0],
          // #GET LAST 105 ELEMENTS
          data: res['data'].slice(-105).map(x => ({ 'time': x.time, 'value': x.value }))
        })
        this.addMarkers(res)
      }).catch(() => { }).then(() => {
        getRLInputData()
          .then((res) => {
            var pairs = []
            for (var i = 0; i < res['inputs'].length; i++) {
              var timeArr = res['data'][i].map(x => new Date(x.time * 1000))
              var valArr = res['data'][i].map(x => x.value)
              pairs.push({ name: res['inputs'][i], timeArr: timeArr, valArr: valArr })
            }
            this.setState({ ...this.state, pairs: pairs, inputPairs: res['inputs'] });

          }).catch(() => { }).then(() => {
            getLiveSentiment('BTC-USD', "crypto_1h")
              .then((res) => {
                try {
                  this.setState({ ...this.state, actualResults: res.y, labels: res.labels });
                } catch { }
              })
              .then(() => {
                getTweets('BTC-USD', "crypto_1h").then((res) => {
                  try {
                    this.setState({ ...this.state, tweets: res.arr });
                    this.chart1.current.timeScale().fitContent()
                  } catch { }
                }).then(() => {
                  getNews('BTC-USD', "crypto_1h").then((res) => {
                    try {
                      this.setState({ ...this.state, news: res.arr });
                      // this.chart1.current.timeScale().fitContent()
                    } catch { }
                  }).then(() => {
                    getLiveSentimentNews('BTC-USD', "crypto_1h")
                      .then((res) => {
                        try {
                          this.setState({ ...this.state, actualResultsNews: res.y, labelsNews: res.labels });
                        } catch { }
                      })
                  })
                }).catch(() => { })
              })
          })
      })
    }
  }


  runModel() {
    if (this.state.botRun !== true) {
      this.clearMarkersAndData()
      this.setState({
        ...this.state, botRun: !this.state.botRun
      }, () => {
        this.rlModelUpdate(new Date().getHours())
      });
    }
    else {
      this.clearMarkersAndData()
      this.setState({
        ...this.state, botRun: !this.state.botRun, data: []
      })
    }

    if (this.checkModelInterval == null && !this.state.botRun == true)
      this.checkModelInterval = setInterval(() => {
        if (this.lastUpdateHour != new Date().getHours()) {
          this.rlModelUpdate(new Date().getHours())
        }
      }, 5000)
    else {
      clearInterval(this.checkModelInterval)
      this.checkModelInterval = null
    }
  }

  changeModel(event) {

  }

  update() {

  }



  checkBuyStatus(confidence, predictions) {

  }

  change(event) {

  }


  componentWillUnmount() {
    if (this.timerUpdate != null) clearInterval(this.timerUpdate);
  }
  // const [, setSidebarOpen] = useState(false);


  componentDidMount() {
    getCurrentModelData().then(res => {
      this.setState({ ...this.state, rlModelDetals: res, indexNameFull: res.model_options_select_default['label'], indexName: res.model_options_select_default['label'].replace('-', ''), symbol: res.display })
    }).catch((error) => { }).then( () => {
      getCurrentAsset().then( res => {
        this.setState({ ...this.state, rlCurrentAsset: res})
        })
      })
    /*getUseModelData().then(res => {
      this.setState({ ...this.state, rlModelDetals: res })
    })*/
  }


  render() {
    // const { labels, actualResults, predictions } = this.state;
    // const chartConfig = getConfig(labels, actualResults, predictions);
    const radius = 150;
    const interpolate = interpolateRgb("red", "lime");
    const fillColor = interpolate(this.state.confidenceLevel / 100);
    const gradientStops = [
      {
        key: "0%",
        stopColor: color(fillColor).darker(0.5).toString(),
        stopOpacity: 1,
        offset: "0%",
      },
      {
        key: "50%",
        stopColor: fillColor,
        stopOpacity: 0.75,
        offset: "50%",
      },
      {
        key: "100%",
        stopColor: color(fillColor).brighter(0.5).toString(),
        stopOpacity: 0.5,
        offset: "100%",
      },
    ];

    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={() => this.setSidebarOpen(false)} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header  setSidebarOpen={() => this.setSidebarOpen(true)} />
          {/* <Header /> */}

          <main>
            <div class="mx-auto">
              <div class="px-0 " style={{ background: "black" }}>
                <div class="px-0 mx-auto">
                  <div class="flex flex-row content-center self-center bg-blue">
                    <div style={{ height: "50px", width: "60px", overflow: "hidden" }} className="ml-6">
                      <img style={{ objectFit: "fill" }} className="w-full" src={process.env.PUBLIC_URL + '/dashboardlogo.png'} alt="Login" />
                    </div>

                    <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-2xl tracking-wider ml-4  text-blue-900 font-bold uppercase justify-center self-center ">Dashboard</span>
                  </div>
                </div>
              </div>


              <section style={{ fontFamily: "Roboto !important" }}  class="m-2 bg-gray-700">
                <div class=" px-4 py-2 mx-auto">
                  <div class="text-white mb-2"> 
                      <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Bot Configuration</span> 
                  </div>
                  <div class="flex flex-wrap bg-white">
                 
                    <div className="flex flex-wrap">
                      <div class="flex-1  p-4 rounded-2xl bg-blend-color bg-white">
                        <div className="panel">
                          
                          <div class="grid grid-cols-3 gap-2  m-5">
                            <div>
                              <h3 style={{ fontFamily: "Roboto,arial,sans-serif", color: "black" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Algorithm:</h3>
                            </div>
                            <div>
                              {this.state.rlModelDetals.rlalgorithm_options_select_default?.value}
                            </div>
                            <div>
                            {this.state.botRun ? (
                              <button style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} class=" font-medium text-white bg-red-500 hover:bg-green-600 rounded transition duration-200 p-1 w-40" onClick={this.runModel}>
                                STOP
                              </button>) : (<button style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} class=" font-medium text-white bg-green-500 hover:bg-green-600 rounded transition duration-200 p-1 w-40" onClick={this.runModel}>
                                START
                              </button>)
                            }
                            </div>


                            <div>
                              <h3 style={{ fontFamily: "Roboto,arial,sans-serif", color: "black" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Asset:</h3>
                            </div>
                            <div>
                              {this.state.rlModelDetals.model_options_select_default?.value}
                            </div>
                            <div>
                            {!this.state.botRun &&
                              <button style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} class="font-medium text-white bg-gray-500 hover:bg-gray-600 rounded transition duration-200 p-1 w-40" onClick={this.loadModel}>
                                LOAD MODEL
                              </button>
                            }
                            </div>

                          </div>


                          {/* <table className="mt-2 table-auto w-full">
                            <thead className="text-xs uppercase text-gray-800 bg-white-50 rounded-sm">
                              <tr>
                                <th className="p-2">
                                  Parameters
                                </th>
                                <th className="p-2">
                                  Value
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-xs font-medium divide-y divide-gray-100">
                              {["stopLoss", "strategy", "orderType", "baseOrderType", "baseOrderLimit", "maxOrder", "takeProfit", "stopLoss", "skipPoints"].map((item, i) => {
                                return (
                                  <tr>
                                    <td className="p-2">
                                      {item}
                                    </td>
                                    <td className="p-2">
                                      {this.state.rlModelDetals[item]}
                                    </td>
                                  </tr>)
                              })}


                            </tbody>
                          </table> */}

                          <div className="grid gap-4 grid-cols-2 grid-rows-1">
                            
                           
                          </div>
                         
                        </div>
                      </div>

                      {this.state.botRun &&
                      <div class="flex flex-wrap bg-white">
                            <div class="mb-2 flex-1 w-450" style={{maxWidth: "550px"}} >
                              <h3 class="mt-4"><b className="text-lg">Recommended Action:</b></h3>

                              <p class="text-sm">Updated: {this.state.lastUpdate}</p>
                              <br></br>
                              <div className="grid grid-cols-3 gap-4">

                                <div>
                                <p style={{ fontFamily: "Roboto,arial,sans-serif", color: "black" }} className="text-sm tracking-wider font-bold uppercase justify-center self-center" >Next Action</p>
                                </div>
                                <div>
                                <p class="text-sm"> {this.state.executeHour}</p>
                                </div>
                                <div>
                                <p style={{maxWidth: "200px"}} class={`rounded-full text-white ${this.state.currentAction == "buy" ? "bg-green-500" : (this.state.currentAction == "sell" ? "bg-red-500" : "bg-gray-500")}  text-center`}>
                                  {this.state.currentAction == "buy" ? (
                                    <span>
                                      <FaArrowAltCircleUp class="inline mr-4"></FaArrowAltCircleUp>BUY
                                    </span>
                                  ) :
                                    (this.state.currentAction == "sell" ? (
                                      <span>
                                        <FaArrowAltCircleDown class="inline mr-4"></FaArrowAltCircleDown>SELL
                                      </span>
                                    ) : (<span>
                                      <FaPauseCircle class="inline mr-4"></FaPauseCircle>NO ACTION
                                    </span>)
                                    )}
                                </p>
                                </div>

                                <div>
                                <p style={{ fontFamily: "Roboto,arial,sans-serif", color: "black" }} className="text-sm tracking-wider font-bold uppercase justify-center self-center" >Last Action</p>
                                </div>
                                <div>
                                <p class="text-sm">{this.state.lastActionDate}</p>
                                </div>
                                <div>
                                <p style={{maxWidth: "200px"}}  class={`rounded-full text-white ${this.state.lastAction == "buy" ? "bg-green-500" : (this.state.lastAction == "sell" ? "bg-red-500" : "bg-gray-500")} text-center`}>
                                  {this.state.lastAction == "buy" ? (
                                    <span>
                                      <FaArrowAltCircleUp class="inline mr-4"></FaArrowAltCircleUp>BUY
                                    </span>
                                  ) :
                                    (this.state.lastAction == "sell" ? (
                                      <span>
                                        <FaArrowAltCircleDown class="inline mr-4"></FaArrowAltCircleDown>SELL
                                      </span>
                                    ) : (<span>
                                      <FaPauseCircle class="inline mr-4"></FaPauseCircle>NO ACTION
                                    </span>)
                                    )}
                                </p>
                                </div>


                              </div>
                              <div class="bg-slate-50 grid grid-cols-1 gap-4 place-content-center h-38 ...">
                              
                              </div>
                             
                            </div>
                            </div>
                          }
                        <div class="flex-auto"></div>
                    </div>
                  
                    <div class="w-full row">

                      <div className="grid grid-cols-12 gap-6">
                        <div style={{ width: "100%" }} className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
                          <div style={{ position: "sticky" }}>
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 99999999,
                                bottom: "30px",
                                width: "100px",
                                height: "45px",
                                background: "white",
                                marginLeft: "1px",
                                borderRadius: "80px",
                                textAlign: "center",
                                padding: "8px",
                              }}
                            >
                              {this.state.indexName}
                            </div>

                            <TradingViewWidget interval="60" timezone={"Europe/London"} allow_symbol_change={false} save_image={false} width="100%" style={{ width: "100%" }} symbol={this.state.symbol} />
                          </div>
                        </div>
                      </div>
                      {this.state.botRun &&
                        <div class="row">
                          <div className="grid grid-cols-12 gap-6">
                            <div style={{ width: "100%" }} className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
                              <div style={{ position: "sticky" }}>
                                <tw.Chart ref={this.chart1} layout={this.state.chart.layout}
                                  watermark={this.state.chart.watermark}
                                  crosshair={this.state.chart.crosshair}
                                  grid={this.state.chart.grid}
                                  localization={this.state.chart.localization}
                                  timeScale={this.state.chart.timeScale}
                                  height={200}
                                >
                                  <tw.LineSeries ref={this.series1}
                                    lineColor='gray' data={this.state.data} markers={this.state.markers} reactive={true} />
                                </tw.Chart>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  {/* Cards */}
                </div>
              </section>

              {this.state.botRun && false &&
                <div class="py-8 px-2 bg-white">
                  <div class="container px-4 mx-auto">
                    <h2 class="text-2xl font-bold">
                      <span>
                        <FaCoins class="inline mr-4 mb-1"></FaCoins>
                        RL MODEL INPUTS
                      </span>
                    </h2>
                  </div>
                </div>
              }
              {this.state.botRun && false &&
                <section class="py-8">
                  <div class="px-2 mx-auto">
                    <div class="flex flex-wrap -m-4">
                      <div class="w-full lg:w-2/6 p-2">
                        <div class="p-4  bg-blend-color bg-white">
                          <div className="overflow-x-auto" style={{ height: "200px" }}>
                            <h3 class="m-1"><b className="text-lg">Social Media</b></h3>
                            <table className="table-auto w-full">
                              {/* Table header */}
                              <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                                <tr>
                                  <th className="p-2">
                                    <div className="font-semibold text-left">Date</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-center">Tweet</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-center">Sentiment</div>
                                  </th>
                                </tr>
                              </thead>
                              {/* Table body */}
                              <tbody className="text-sm font-medium divide-y divide-gray-100">
                                {/* Row */}
                                {this.state.tweets.map((item, i) => {
                                  return (
                                    <tr>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="text-gray-800">{item[0]}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">{item[1]}</td>
                                      <td className="p-2">{item[2]}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>

                          </div>
                          <div>
                            <LineChart data={this.getConfigTwitter()} height={180} />
                          </div>
                        </div>

                        <div class="p-4  bg-blend-color bg-white">
                          <div className="overflow-x-auto" style={{ height: "200px" }}>
                            <h3 class="m-1"><b className="text-lg">News</b></h3>
                            <table className="table-auto w-full">

                              <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                                <tr>
                                  <th className="p-2">
                                    <div className="font-semibold text-left">Date</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-center">Content</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-center">Sentiment</div>
                                  </th>
                                </tr>
                              </thead>

                              <tbody className="text-sm font-medium divide-y divide-gray-100">

                                {this.state.news.map((item, i) => {
                                  return (
                                    <tr>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="text-gray-800">{item[0]}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">{item[1]}</td>
                                      <td className="p-2">{item[2]}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div>
                            <LineChart data={this.getConfigNews()} height={180} />
                          </div>
                        </div>

                      </div>

                      <div class="w-full lg:w-4/6 p-2">
                        <div class="grid grid-cols-4 gap-4 place-content-center ...">
                          {this.state.inputPairs.map((item, i) => {
                            return (
                              <div>
                                <div style={{ height: "180px" }}>
                                  {item}
                                  <LineChart data={this.getConfig(i)} width={389} height={180} />
                                </div>
                              </div>)
                          })}

                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }
            </div>
          </main>

          <Banner />
        </div>
      </div>
    );
  }
}

export default RLBotLive;
