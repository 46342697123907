import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import Banner from "../partials/Banner";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import Modal from 'react-modal';

import { getUserList, removeUser, changeUserPassword, addUser } from "../serives/backendService";
import Select from "react-select";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

let subtitle;

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = { users: [], modalIsOpen: false, setIsOpen:false, modalIsOpenChangePassword:false, modalIsOpenAddUser:false, userID: 0, userName:"user", newPassword: "643tydfsdy273452" };
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.removeUser = this.removeUser.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.addUser = this.addUser.bind(this)

    this.openModalChangePassword = this.openModalChangePassword.bind(this)
    this.afterOpenModalChangePassword = this.afterOpenModalChangePassword.bind(this)
    this.closeModalChangePassword = this.closeModalChangePassword.bind(this)

    this.openModalAddUser = this.openModalAddUser.bind(this)
    this.afterOpenModalAddUser = this.afterOpenModalAddUser.bind(this)
    this.closeModalAddUser = this.closeModalAddUser.bind(this)
    this.returnUserName = this.returnUserName.bind(this)
    this.changePasswordInput = this.changePasswordInput.bind(this)
    this.changeUserInput = this.changeUserInput.bind(this)
    this.refreshSite = this.refreshSite.bind(this)
    // this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  changePasswordInput(val){
    this.setState({ ...this.state, newPassword: val})
  } 

  changeUserInput(val){
    this.setState({ ...this.state, userName: val})
  } 

  returnUserName(id){
    try{
    var userName = this.state.users.filter(el => el.id === id)[0]
    if (userName !== null)
      return userName["user"]}
      catch{
        return ""    
      }
    return ""
  }

  removeUser(){
    removeUser(this.state.userID).then( (res) => {
      this.setState({ ...this.state, modalIsOpen: false})
    }).then( ()=> this.refreshSite())
  }

  changePassword(){
    changeUserPassword(this.state.userID, this.state.newPassword).then((res)=> {
      this.setState({ ...this.state, modalIsOpenChangePassword: false})
    }).then( ()=> this.refreshSite())
  }

  addUser(){
    addUser(this.state.userName, this.state.newPassword).then((res)=> {
      this.setState({ ...this.state, modalIsOpenAddUser: false})
    }).then( ()=> this.refreshSite())
  }

  openModalAddUser(){
    this.setState({ ...this.state, modalIsOpenAddUser: true})
  }

  afterOpenModalAddUser(){
    subtitle.style.color = '#f00';
  }

  closeModalAddUser(){
    this.setState({ ...this.state, modalIsOpenAddUser: false})
  }

  openModal(userID) {
    this.setState({ ...this.state, modalIsOpen: true, userID: userID})
   
  }
  
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }
  
  closeModal() {
    this.setState({ ...this.state, modalIsOpen: false})
  }

  openModalChangePassword(userID) {
    this.setState({ ...this.state, modalIsOpenChangePassword: true,  userID: userID})
   
  }
  
  afterOpenModalChangePassword() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }
  
  closeModalChangePassword() {
    this.setState({ ...this.state, modalIsOpenChangePassword: false})
  }

  refreshSite(){
    getUserList().then( res => {
      this.setState({ ...this.state, users: res.users})
      console.log(res)
    })
  }
  componentDidMount() {
    getUserList().then( res => {
      this.setState({ ...this.state, users: res.users})
      console.log(res)
    })
  }

  render() {
    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          {/* <Header /> */}

          <main>
          <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Remove User"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Remove user</h2>
         
        <div className="mx-3">Are you sure to remove user {this.returnUserName(this.state.userID)}</div>
        <div className="flex flex-col col-span-full">
          <span className="flex items-center justify-between h-16 -mb-px">
          <button  onClick={this.closeModal} className="btn bg-gray-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Cancel</span>
                                     </button>    
          <button  onClick={this.removeUser} className="btn bg-red-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Remove</span>
        </button>
          </span>
         
        </div>
      </Modal>

      <Modal
        isOpen={this.state.modalIsOpenChangePassword}
        onAfterOpen={this.afterOpenModalChangePassword}
        onRequestClose={this.closeModalChangePassword}
        style={customStyles}
        contentLabel="Remove User"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Change password</h2>
         
        <div className="mx-3">Please enter new password</div>
        <div class="py-2">
                <input placeholder="" type="password" onChange={e => this.changePasswordInput(e.target.value)}
                  class="text-md block px-3 py-2  rounded-lg w-full 
                bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"></input>
              </div>
        <div className="flex flex-col col-span-full">
          <span className="flex items-center justify-between h-16 -mb-px">
          
          <button  onClick={this.closeModalChangePassword} className="btn bg-gray-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Cancel</span>
                                     </button>    
          <button  onClick={this.changePassword} className="btn bg-red-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Change Password</span>
        </button>
          </span>
         
        </div>
      </Modal>

      <Modal
        isOpen={this.state.modalIsOpenAddUser}
        onAfterOpen={this.afterOpenModalAddUser}
        onRequestClose={this.closeModalAddUser}
        style={customStyles}
        contentLabel="Remove User"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Add User</h2>
        <div className="mx-3">New user name</div>
        <div class="py-2">
                <input placeholder="" type="text" onChange={e => this.changeUserInput(e.target.value)}
                  class="text-md block px-3 py-2  rounded-lg w-full 
                bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"></input>
              </div>
        <div className="mx-3">New password</div>
        <div class="py-2">
                <input placeholder="" type="password" onChange={e => this.changePasswordInput(e.target.value)}
                  class="text-md block px-3 py-2  rounded-lg w-full 
                bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"></input>
              </div>
        <div className="flex flex-col col-span-full">
          <span className="flex items-center justify-between h-16 -mb-px">
          <button  onClick={this.closeModalAddUser} className="btn bg-gray-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Cancel</span>
                                     </button>    
          <button  onClick={this.addUser} className="btn bg-red-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Add User</span>
        </button>
          </span>
         
        </div>
      </Modal>


              {/* Cards */}
              <div className="grid grid-cols-6 gap-2">
                <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
                  <header className="px-5 py-4 border-b border-gray-100">
                    <h2 className="font-semibold text-gray-800">Users</h2>
                  </header>
                  <div className="p-3">
                    {/* Table */}
                    <div className="overflow-x-auto">
                      <table className="table-auto w-full">
                        {/* Table header */}
                        <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                          <tr>
                            <th className="p-2">
                              <div className="font-semibold text-left" style={{width:'100%'}}>User Name</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-center" style={{width:'200px'}}>Change password</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-center" style={{width:'100px'}}>Remove</div>
                            </th>
                          </tr>
                        </thead>
                        {/* Table body */}
                        <tbody className="text-sm font-medium divide-y divide-gray-100">
                          {/* Row */}
                          {
                            this.state.users.map((val) => {  
                               return <tr>
                                  <td className="p-2" >
                                    <div className="flex items-center">
                                      <div className="text-gray-800">{val.user}</div>
                                    </div>
                                  </td>
                                  <td className="p-2" style={{width:'200px'}}>
                                    <div className="text-center text-light-blue-500">
                                    <button  onClick={() => this.openModalChangePassword(val.id)} className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Change Password</span>
                                     </button>     

                                    </div>
                                  </td>
                                  <td className="p-2" style={{width:'100px'}}>
                                    <div className="text-center text-light-blue-500">
                                    <button  onClick={() => this.openModal(val.id)}  className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Remove</span>
                                     </button>  
                                    </div>
                                  </td>
                                </tr>
                            })
                           }
                          
                          {/* Row */}
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
                <div className="p-3">
                <header className="px-5 py-4 border-b border-gray-100">
                    <h2 className="font-semibold text-gray-800">Actions</h2>
                  </header>
                    {/* Table */}
                    <div className="overflow-x-auto">
                    <button  onClick={this.openModalAddUser} className="btn bg-blue-500 hover:bg-indigo-600 text-white">
                                         <span className="hidden xs:block ml-2">Add user</span>
                          </button>
                      </div>
                      </div>
                  </div>
              </div>
  
          </main>

          <Banner />
        </div>
      </div>
    );
  }
}

export default Users;
