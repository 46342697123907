import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import Banner from "../partials/Banner";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import { tailwindConfig, hexToRGB, formatValue } from "../utils/Utils";
import { FaArrowRight, FaArrowLeft, FaAdjust, FaLaptopHouse, FaCoins, FaChartLine, FaArrowAltCircleDown, FaArrowAltCircleUp, FaPauseCircle } from "react-icons/fa";
import { getData, getIndexesForModel, getNews, getLiveSentimentNews, getSentimentAnalysisNews, getRLAlgorithms, getRLAlgorithmConfig } from "../serives/backendService";
import Select from "react-select";
import LineChart from "../charts/LineChart03";
import DoughnutChart from "../charts/DoughnutChart";
import { PieChart } from "react-minimal-pie-chart";

const getConfigDounought = (sentiment) => ({
  labels: ["Positive", "Neutral", "Negative"],
  datasets: [
    {
      label: "Top Countries",
      data: sentiment,
    },
  ],
});



const getConfig = (labels, actualResults, volume) => ({
  labels: labels,
  datasets: [
    // Indigo line
    {
      data: actualResults,
      fill: true,
      backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
      borderColor: tailwindConfig().theme.colors.indigo[500],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      clip: 20,
    },
  ],
});

const options = { style: "currency", currency: "USD" };
const numberFormat = new Intl.NumberFormat("en-US", options);

class SentimentNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOnline: null, dataResults: [], actualResults: [], targetResults: [], mape: 0, mae: 0, modelInfo: "", options_select: [], model_options_select: [], tweets: [], labels: [], volume: [], currentIndex: "BTC-USD", pieData: [],  sidebarOpen: false };
    this.change = this.change.bind(this);
    this.setSidebarOpen = this.setSidebarOpen.bind(this)
    console.log(this.state.data);
    this.changeModel = this.changeModel.bind(this);
    this.interval = null;
    this.getAlgorithmDetails = this.getAlgorithmDetails.bind(this)
    // this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  async getAlgorithmDetails() {
    try {
      const rlAlgorithmsResponse = await getRLAlgorithms();
      const rlAlgorthms = rlAlgorithmsResponse['data'].map(x => ({ 'value': x, 'label': x }));
      // this.setState({ ...this.state, rlalgorithm_options_select: rlAlgorthms, rlalgorithm_options_select_default: rlAlgorthms[0] });
      console.log(rlAlgorithmsResponse);

      const rlAlgorithmConfigResponse = await getRLAlgorithmConfig(rlAlgorthms[0].label);
      const rlPairs = rlAlgorithmConfigResponse['data'].indexes.split(' ').map(x => ({ 'value': x, 'label': x }));
      console.log(rlAlgorithmConfigResponse);
      this.setState({ ...this.state, currentIndex: rlAlgorithmConfigResponse.data.keyword_sentiment });
      return rlAlgorithmConfigResponse.data.indexes;
    } catch (error) {
      // Handle errors here
      console.error(error);
      // Optionally, rethrow the error to propagate it further
      throw error;
    }
  }

  setSidebarOpen(statusLocal) {
    console.log("Executed!!!")
    console.log(this.state.sidebarOpen)
    this.setState({...this.state, sidebarOpen: statusLocal})
  }
  
  changeModel(event) { }

  change(event) {
    getLiveSentimentNews(event.value, "crypto_1h").then((res) => {
      try {
        this.setState({ ...this.state, actualResults: res.y, labels: res.labels, currentIndex: event.value });
      } catch { }
    });
    getNews(event.value, "crypto_1h").then((res) => {
      try {
        this.setState({ ...this.state, tweets: res.arr });
      } catch { }
    });
    getSentimentAnalysisNews(event.value, "crypto_1h").then((res) => {
      try {
        var pieData = [res.positive, res.neutral, res.negative];
        this.setState({ ...this.state, pieData: pieData });
      } catch { }
    });
    console.log(event.value);
  }

  componentWillUnmount() {
    if (this.interval != null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  componentDidMount() {
    // document.getElementById('myframe1').contentWindow.document.getElementById('x')
    try {
      this.getAlgorithmDetails().then(() => {
        getNews(this.state.currentIndex, "crypto_1h")
          .then((resTweets) => {
            try {
              this.setState({ ...this.state, tweets: resTweets.arr });
            } catch (e) {
              console.log(e);
            }
          })
          .then(() => {
            getLiveSentimentNews(this.state.currentIndex, "crypto_1h").then((res) => {
              try {
                this.setState({ ...this.state, actualResults: res.y, labels: res.labels });
              } catch (e) {
                console.log(e);
              }
            });
          })
          .then(() => {
            getSentimentAnalysisNews(this.state.currentIndex, "crypto_1h").then((res) => {
              try {
                var pieData = [res.positive, res.neutral, res.negative];
                this.setState({ ...this.state, pieData: pieData });
              } catch { }
            });
          })
          .then(() => {
            if (this.interval != null) {
              this.interval = setInterval(() => {
                getLiveSentimentNews(this.state.currentIndex, "crypto_1h")
                  .then((res) => {
                    try {
                      this.setState({ ...this.state, actualResults: res.y, labels: res.labels });
                    } catch { }
                  })
                  .then(() => {
                    getNews(this.state.currentIndex, "crypto_1h").then((res) => {
                      try {
                        this.setState({ ...this.state, tweets: res.arr });
                      } catch { }
                    });
                  })
                  .then(() => {
                    getSentimentAnalysisNews(this.state.currentIndex, "crypto_1h").then((res) => {
                      try {
                        var pieData = [res.positive, res.neutral, res.negative];
                        console.log(pieData);
                        this.setState({ ...this.state, pieData: pieData });
                      } catch { }
                    });
                  });
              }, 10000);
            }
          });
      })
    }
    catch { }
  }

  render() {
    const { labels, actualResults, volume } = this.state;
    const chartConfig = getConfig(labels, actualResults, volume);

    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={() => this.setSidebarOpen(false)} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header  setSidebarOpen={() => this.setSidebarOpen(true)} />
          {/* <Header /> */}

          <main>
            <div class="mx-auto">

              <div class="px-0 " style={{ background: "black" }}>
                <div class="px-0 mx-auto">
                  <div class="flex flex-row content-center self-center bg-blue">
                    <div style={{ height: "50px", width: "60px", overflow: "hidden" }} className="ml-6">
                      <img style={{ objectFit: "fill" }} className="w-full" src={process.env.PUBLIC_URL + '/dashboardlogo.png'} alt="Login" />
                    </div>

                    <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-2xl tracking-wider ml-4  text-blue-900 font-bold uppercase justify-center self-center ">Sentiment News</span>
                  </div>
                </div>
              </div>


              <section style={{ fontFamily: "Roboto !important" }} class="m-2 bg-gray-700">
                <div class=" px-4 py-2 mx-auto">
                  <div class="text-white mb-2">
                    <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Live Sentiment</span>
                  </div>
                  <div class="flex flex-wrap bg-white">
                    <div class="flex-1 p-4 rounded-2xl bg-blend-color bg-white">
                      <div className="w-full px-4 sm:px-6 lg:px-8  w-full max-w-9xl mx-auto">
                        {/* Dashboard actions */}
                        {/* <div className="sm:flex sm:justify-between sm:items-center mb-8">
                              <div className="grid grid-flow-col sm:auto-cols-max justify-end sm:justify-end gap-2"> 
                                <div style={{ width: "300px" }}>
                                  Index
                                  <Select width="400px" defaultValue={this.state.options_select[0]} style={{ width: "600px" }} onChange={this.change} options={this.state.options_select} />
                                </div>
                              </div>
                            </div> */}

                        {/* Cards */}
                        <div className="grid grid-cols-12 gap-6">
                          <div style={{ width: "100%" }} className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white  bg-blend-color bg-white">
                            {/* <div className="px-5 pt-5">
                                  <header className="flex justify-between items-start mb-2">
                                    <h2>Live Sentiment for {this.state.currentIndex}</h2>
                                  </header>
                                </div> */}
                            <div className="flex-grow">
                              {/* Change the height attribute to adjust the chart height */}
                              <LineChart data={chartConfig} width={389} height={500} />
                            </div>
                          </div>
                          <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-9 md:col-span-6 bg-white shadow-lg border border-gray-200 rounded-2xl bg-blend-color bg-white">
                            <header className="px-5 py-4 border-b border-gray-100">
                              <h2 className="font-semibold text-gray-800">Statistics</h2>
                            </header>
                            <div className="p-3">
                              {/* Table */}
                              <div className="overflow-x-auto" style={{ height: "400px" }}>
                                <table className="table-auto w-full">
                                  {/* Table header */}
                                  <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                                    <tr>
                                      <th className="p-2">
                                        <div className="font-semibold text-left">Date</div>
                                      </th>
                                      <th className="p-2">
                                        <div className="font-semibold text-center">Tweet</div>
                                      </th>
                                      <th className="p-2">
                                        <div className="font-semibold text-center">Sentiment</div>
                                      </th>
                                    </tr>
                                  </thead>
                                  {/* Table body */}
                                  <tbody className="text-sm font-medium divide-y divide-gray-100">
                                    {/* Row */}
                                    {this.state.tweets.map((item, i) => {
                                      return (
                                        <tr>
                                          <td className="p-2">
                                            <div className="flex items-center">
                                              <div className="text-gray-800">{item[0]}</div>
                                            </div>
                                          </td>
                                          <td className="p-2">{item[1]}</td>
                                          <td className="p-2">{item[2]}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-3 md:col-span-6 bg-white shadow-lg border border-gray-200 rounded-2xl bg-blend-color bg-white">
                            <header className="px-5 py-4 border-b border-gray-100">
                              <h2 className="font-semibold text-gray-800">Positive vs Negative sentiment for {this.state.currentIndex}</h2>
                            </header>
                            <div class="m-auto w-60 h-60">
                              <PieChart
                                width={389}
                                height={400}
                                data={[
                                  { title: "POSITIVE", value: this.state.pieData[0], color: "#0369a1" },
                                  { title: "NEUTRAL", value: this.state.pieData[1], color: "#ca8a04" },
                                  { title: "NEGATIVE", value: this.state.pieData[2], color: "#e11d47" },
                                ]}
                              />
                            </div>
                            <div class="p-5">
                              <table class="table p-6">
                                <tbody>
                                  <tr class="p-4">
                                    <td class="p-2 font-bold">POSITIVE</td>
                                    <td class="bg-blue-600 text-center text-white">{this.state.pieData[0]}</td>
                                  </tr>
                                  <tr class="p-4">
                                    <td class="p-2 font-bold">NEUTRAL</td>
                                    <td class="bg-yellow-600 text-center text-white">{this.state.pieData[1]}</td>
                                  </tr>
                                  <tr class="p-4">
                                    <td class="p-2 font-bold">NEGATIVE</td>
                                    <td class="bg-red-600 text-center text-white">{this.state.pieData[2]}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/* <DoughnutChart data={chartConfigSentiment} width={389} height={400} /> */}
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
              </section>


            </div>
          </main>

          <Banner />
        </div>
      </div>
    );
  }
}

export default SentimentNews;
