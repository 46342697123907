import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import Banner from "../partials/Banner";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import { FaArrowRight, FaArrowLeft, FaAdjust, FaLaptopHouse, FaCoins, FaChartLine, FaArrowAltCircleDown, FaArrowAltCircleUp, FaPauseCircle } from "react-icons/fa";
import { getData, getIndexesForModel, changeModel, getModels } from "../serives/backendService";
import Select from "react-select";

const getConfig = (data, actualResults, target) => ({
  yAxis: [
    {
      offset: 20,

      labels: {
        formatter: function () {
          return numberFormat.format(this.value);
        },
        x: -15,
        style: {
          color: "#000",
          position: "absolute",
        },
        align: "left",
      },
    },
  ],

  plotOptions: {
    series: {
      showInNavigator: true,
      gapSize: 6,
    },
  },
  rangeSelector: {
    selected: 1,
  },
  title: {
    text: `Bitcoin stock price`,
  },
  chart: {
    height: 700,
  },

  credits: {
    enabled: false,
  },

  legend: {
    enabled: true,
  },
  xAxis: {
    type: "datetime",
  },
  rangeSelector: {
    buttons: [
      {
        type: "day",
        count: 1,
        text: "1d",
      },
      {
        type: "day",
        count: 3,
        text: "3d",
      },
      {
        type: "day",
        count: 15,
        text: "15d",
      },
      {
        type: "month",
        count: 1,
        text: "1m",
      },
      {
        type: "month",
        count: 3,
        text: "3m",
      },
      {
        type: "all",
        text: "All",
      },
    ],
    selected: 1,
  },
  series: [
    {
      name: "Prediction Past",
      type: "line",

      boostThreshold: 5000,
      data: data, //priceData,
    },
    {
      name: "Prediction Future",
      type: "line",
      boostThreshold: 5000,
      data: target, //priceData,
    },
    {
      name: "Acctual",
      type: "line",
      boostThreshold: 5000,
      data: actualResults,
    },
  ],
});

const options = { style: "currency", currency: "USD" };
const numberFormat = new Intl.NumberFormat("en-US", options);

class Predictions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { model_options_select_default: null, isEnbled: true, options_select_default: null, isOnline: null, dataResults: [], actualResults: [], targetResults: [], mape: 0, mae: 0, modelInfo: "", options_select: [], model_options_select: [] };
    this.change = this.change.bind(this);
    console.log(this.state.data);
    this.changeModel = this.changeModel.bind(this);
    // this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  changeModel(event) {
    this.setState({ ...this.state, isEnbled: false })
    changeModel(event.value)
      .then(() => {
        getIndexesForModel(event.value).then((res) => {
          this.setState({ ...this.state, options_select: res, model_options_select_default: event, isEnbled: true });
        })
        .then((res) => {
            this.change(this.state.options_select[0]);
        })
        .catch( error => {console.log(error)});
      }).catch( error => {console.log(error)});
      ;
  }

  change(event) {
    getData(event.value, this.state.model_options_select_default.value).then((res) => {
      this.setState({ ...this.state, dataResults: res.ret, actualResults: res.acct, targetResults: res.target, mape: (res.mape * 100).toFixed(2), mae: res.mae.toFixed(2), modelInfo: res.info, options_select_default: event });
    }).catch( error => {console.log(error)});;
    console.log(event.value);
  }
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  componentDidMount() {
    // document.getElementById('myframe1').contentWindow.document.getElementById('x')
    getModels()
    .then((res) => {
      this.setState({ ...this.state, model_options_select: res.models, model_options_select_default: res.models[0] })
    })
    .then(() => {
      getIndexesForModel(this.state.model_options_select_default.value)
      .then((res) => {
        this.setState({ ...this.state, options_select: res, options_select_default: res[0] });
        getData(this.state.options_select_default.value, this.state.model_options_select_default.value)
        .then((res) => {
          console.log(res);
          this.setState({ ...this.state, dataResults: res.ret, actualResults: res.acct, targetResults: res.target, mape: (res.mape * 100).toFixed(2), mae: res.mae.toFixed(2), modelInfo: res.info });
        }).catch( error => {console.log(error)});
      }).catch( error => {console.log(error)});
    }).catch( error => {console.log(error)}); 
  }

  render() {
    const { dataResults, actualResults, targetResults } = this.state;
    const chartConfig = getConfig(dataResults, actualResults, targetResults);
    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          {/* <Header /> */}

          <main>
          <div class="container mx-auto">
          <div class="py-8 px-2 bg-white">
              <div class="container px-4 mx-auto">
                <h2 class="text-2xl font-bold">
                  <span>
                    <FaChartLine class="inline mr-4 mb-1"></FaChartLine>
                    Predictions
                  </span>
                </h2>
              </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
              {/* Dashboard actions */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Avatars */}
                {/* <DashboardAvatars /> */}
                <div style={{ width: "300px" }} disabled={!this.state.isEnbled}>
                  Model
                  <Select width="400px" value={this.state.model_options_select_default} style={{ width: "600px" }} onChange={this.changeModel} options={this.state.model_options_select} />
                </div>
                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-end sm:justify-end gap-2">
                  {/* Filter button */}

                  {/* Datepicker built with flatpickr */}
                  {/* <Datepicker /> */}
                  {/* Add view button */}
                  <div style={{ width: "300px" }} disabled={!this.state.isEnbled}>
                    Index
                    <Select width="400px" value={this.state.options_select_default} style={{ width: "600px" }} onChange={this.change} options={this.state.options_select} />
                  </div>
                  {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                  </button> */}
                </div>
              </div>

              {/* Cards */}
              <div className="grid grid-cols-12 gap-6 ">
                <div style={{ width: "100%" }} className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg h-full border border-gray-200 rounded-2xl bg-blend-color bg-white">
                  <div className="px-5 pt-5">
                    <header className="flex justify-between items-start mb-2">
                      <h2>Predictions</h2>
                    </header>
                  </div>
                  <ReactHighcharts id="myframe1" ref={"chartComponent"} config={chartConfig}></ReactHighcharts>
                  <div><br></br></div>
                </div>
              </div>

              <div className="grid grid-cols-12 mt-4">
                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200  border border-gray-200 rounded-2xl bg-blend-color bg-white">
                  <header className="px-5 py-4 border-b border-gray-100">
                    <h2 className="font-semibold text-gray-800">Statistics</h2>
                  </header>
                  <div className="p-3">
                    {/* Table */}
                    <div className="overflow-x-auto">
                      <table className="table-auto w-full">
                        {/* Table header */}
                        <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                          <tr>
                            <th className="p-2">
                              <div className="font-semibold text-left">Metric</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-center">Value</div>
                            </th>
                          </tr>
                        </thead>
                        {/* Table body */}
                        <tbody className="text-sm font-medium divide-y divide-gray-100">
                          {/* Row */}
                          <tr>
                            <td className="p-2">
                              <div className="flex items-center">
                                <div className="text-gray-800">Mean Asolute Percentage Error (MAPE)</div>
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-center text-light-blue-500">{this.state.mape}%</div>
                            </td>
                          </tr>
                          {/* Row */}
                          <tr>
                            <td className="p-2">
                              <div className="flex items-center">
                                <div className="text-gray-800">Mean Absolute Error (MAE)</div>
                              </div>
                            </td>

                            <td className="p-2">
                              <div className="text-center text-green-500">${this.state.mae}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 border border-gray-200 rounded-2xl bg-blend-color bg-white ml-6">
                  <header className="px-5 py-4 border-b border-gray-100">
                    <h2 className="font-semibold text-gray-800">Info</h2>
                  </header>
                  <div className="p-3">
                    <p>{this.state.modelInfo}</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </main>

          <Banner />
        </div>
      </div>
    );
  }
}

export default Predictions;
