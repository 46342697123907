import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import Predictions from './pages/Predictions';
import Sentiment from './pages/Sentiment';
import Users from './pages/Users';
import Login from './pages/Login';
import TraidingBot from './pages/TraidingBot';
import TransactionHistory from './pages/TransactionHistory';
import RLBot from './pages/RLBot';
import RLBotLive from './pages/RLBotLive';
import SentimentNews from './pages/SentimentNews';
import GPT from './pages/GPT';

function App() {

  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  const [token, setToken] = useState();
  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <>
      <Switch>
      <Route exact path="/">
           <RLBotLive/>
        </Route>
        <Route exact path="/pricepredictions">
          <Dashboard />
        </Route>
        <Route exact path="/predictions">
          <Predictions />
        </Route>
        <Route exact path="/sentiment">
          <Sentiment />
        </Route>
        <Route exact path="/sentimentNews">
          <SentimentNews />
        </Route>
        <Route exact path="/users0987654321k4In">
          <Users />
        </Route>
        <Route exact path="/tradingbot">
          <TraidingBot />
        </Route>
        <Route exact path="/rlbot">
          <RLBot/>
        </Route>
        <Route exact path="/rlbotlive">
          <RLBotLive/>
        </Route>
        <Route exact path="/transactionhistory">
          <TransactionHistory />
        </Route>
        <Route exact path="/GPT">
          <GPT />
        </Route>

        
      </Switch>
    </>
  );
}

export default App;
