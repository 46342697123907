import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import Banner from "../partials/Banner";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import { tailwindConfig, hexToRGB, formatValue } from "../utils/Utils";
import NumberFormat from "react-number-format";
import { getRLPairData, getRLAlgorithms, getRLAlgorithmConfig, getRLBackTest, postUseModelData, getRefreshTestData } from "../serives/backendService";
import Select from "react-select";
import LineChart from "../charts/LineChart03";
import DoughnutChart from "../charts/DoughnutChart";
import MultiSelectListBox from "react-multiselect-listbox";
import { Row, Col, Progress, Table, Button, Input, Label } from "reactstrap";
import TradingViewWidget from "react-tradingview-widget";
import { AreaSeries, Chart, LineSeries } from "lightweight-charts-react-wrapper";
import { FaArrowRight, FaArrowLeft, FaAdjust, FaLaptopHouse, FaCoins, FaChartLine, FaArrowAltCircleDown, FaArrowAltCircleUp, FaPauseCircle } from "react-icons/fa";


class RLBot extends React.Component {
  constructor(props) {
    super(props);
    this.series1 = React.createRef();
    this.onPairChanged = this.onPairChanged.bind(this)
    this.onAlgorithmChanged = this.onAlgorithmChanged.bind(this)
    this.useModel = this.useModel.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.setSidebarOpen = this.setSidebarOpen.bind(this)

    this.state = {
      model_options_select: [],
      model_options_select_default: {},
      rlalgorithm_options_select: [],
      rlalgorithm_options_select_default: {},
      actionList: [],
      backtestText: "",
      runOn: 0,
      totalProfit: 0,
      strategy: 'long',
      orderType: 'limit',
      baseOrderType: 'static',
      selectedTab: "0",
      fundsAllocated: 1000000,
      baseOrderLimit: 50,
      maxOrder: 1,
      takeProfit: 0,
      stopLoss: 0,
      skipPoints: 1,
      pctRet: 0,
      noPoints: 100,
      runBacktestBtnVis: true,
      sidebarOpen: false,
      display: "",
      showModal: false,
      chart: {
        localization: {

        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
        layout: {
          backgroundColor: '#2B2B43',
          lineColor: '#2B2B43',
          textColor: '#D9D9D9',
        },
        watermark: {
          color: 'rgba(0, 0, 0, 0)',
        },
        crosshair: {
          color: '#758696',
        },
        grid: {
          vertLines: {
            color: '#2B2B43',
          },
          horzLines: {
            color: '#363C4E',
          },
        },
      },
      data: [],
      markers: [

      ],
      dataBuy: [],
      dataSell: [],
    }

    this.runBacktest = this.runBacktest.bind(this);
  }

  setSidebarOpen(statusLocal) {
    console.log("Executed!!!")
    console.log(this.state.sidebarOpen)
    this.setState({...this.state, sidebarOpen: statusLocal})
  }

  refreshData() {
    this.setState({ ...this.state, runBacktestBtnVis: false, showModal: true })
    getRefreshTestData(this.state.model_options_select_default?.value, 10000).then(res => {
      this.setState({ ...this.state, runBacktestBtnVis: true, showModal: false })
    }).catch(() => {
      this.setState({ ...this.state, runBacktestBtnVis: true, showModal: false })
    })
  }


  useModel() {
    var dataToSend = {
      ...this.state,
      model_options_select: [],
      rlalgorithm_options_select: [],
      backtestText: "",
      chart: [],
      data: [],
      markers: [],
      dataBuy: [],
      dataSell: [],

    }
    postUseModelData(dataToSend).then(res => {
    }).catch(() => { })
  }


  updateChartWithData() {

  }


  onAlgorithmChanged(e) {
    var s = e
    getRLAlgorithmConfig(e.value).then((res => {
      var rlPairs = res['data'].indexes.split(' ').map(x => ({ 'value': x, 'label': x }));
      this.setState({ ...this.state, model_options_select: rlPairs, model_options_select_default:rlPairs[0], display: res['data'].display });
    })).catch(() => { })
      .then(() => {
        getRLPairData(this.state.model_options_select_default?.value)
          .then((res) => {
            this.setState({ ...this.state, data: res['data'].map(x => ({ 'time': x.time, 'value': x.value })) });
          }).catch(() => { })
      }).catch(() => { })
    this.setState({ ...this.state, rlalgorithm_options_select_default: { value: s.value, label: s.value } })
  }


  onPairChanged(e) {
    getRLPairData(e.value.toLowerCase()).then((res) => {
      this.setState({ ...this.state, data: res['data'].map(x => ({ 'time': x.time, 'value': x.value })) });
      this.series1.current.setMarkers([])
    }).catch(() => { })
    this.setState({ ...this.state, model_options_select_default: { value: e.value, label: e.value } })
  }

  componentDidMount() {
    getRLAlgorithms()
      .then((res) => {
        var rlAlgorthms = res['data'].map(x => ({ 'value': x, 'label': x }))
        this.setState({ ...this.state, rlalgorithm_options_select: rlAlgorthms, rlalgorithm_options_select_default: rlAlgorthms[0] });

        return rlAlgorthms
      })
      .catch(() => { })
      .then(res => {
        getRLAlgorithmConfig(res[0].label).then((res => {
          var rlPairs = res['data'].indexes.split(' ').map(x => ({ 'value': x, 'label': x }));
          this.setState({ ...this.state, model_options_select: rlPairs, model_options_select_default: rlPairs[0], display: res['data'].display });
        })).catch(() => { })
          .then(() => {
            getRLPairData(this.state.model_options_select_default?.value)
              .then((res) => {
                this.setState({ ...this.state, data: res['data'].map(x => ({ 'time': x.time, 'value': x.value })) });
              }).catch(() => { })
          }).catch(() => { })
      }).catch(() => { })
  }

  runBacktest() {

    this.setState({ ...this.state, runBacktestBtnVis: false }, () => {
      getRLBackTest(this.state.rlalgorithm_options_select_default?.value, this.state.model_options_select_default?.value,
        ((this.state.baseOrderLimit / 100) * this.state.fundsAllocated), this.state.maxOrder, this.state.stopLoss, this.state.takeProfit,
        this.state.skipPoints, this.state.strategy, this.state.noPoints).then((res) => {

          var m = []
          var data = res['data'].map(x => ({ 'time': x.time, 'value': x.value }))
          for (let i = 0; i < res["states_buy"].length; i++) {
            var id = res["states_buy"][i]
            m.push(
              {

                time: data[id].time,
                position: 'belowBar',
                color: 'lime',
                shape: 'arrowUp',
                text: 'Buy @ ' + data[id].value,
              }
            )
          }
          for (let i = 0; i < res["states_sell"].length; i++) {
            var id = res["states_sell"][i]
            m.push(
              {

                time: data[id].time,
                position: 'aboveBar',
                color: '#e91e63',
                shape: 'arrowDown',
                text: 'Sell @ ' + data[id].value,
              }
            )
          }
          m = m.sort(function (x, y) {
            return x.time - y.time;
          })


          var actionList = []
          var pctRet = 0
          console.log(actionList)
          for (let i = 0; i < res['action_log'].length; i++) {
            var item = res['action_log'][i]
            var resData = data[item[1]]
            pctRet += Math.round(item[3] * 100) / 100
            console.log("PCT: " + pctRet)
            actionList.push([new Date(resData.time * 1000).toLocaleString(), item[0], '$' + resData.value, '$' + item[2], Math.round(item[3] * 100) / 100, "$" + Math.round(item[4] * 100) / 100, item[2] + Math.round(item[4] * 100) / 100])
          }

          this.series1.current.setMarkers(m)
          var text = "Run on " + res['data'].length + " hours" + " Total gains: " + res["total_gains"]
          this.setState({ ...this.state, data: res['data'].map(x => ({ 'time': x.time, 'value': x.value })), backtestText: text, actionList: actionList, runOn: res['data'].length, totalProfit: res["total_gains"], runBacktestBtnVis: true, pctRet: pctRet });
        }).catch(() => { })
    })
  }



  render() {
    return (
      <>
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={() => this.setSidebarOpen(false)} />

{/* Content area */}
<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
  {/*  Site header */}
  <Header  setSidebarOpen={() => this.setSidebarOpen(true)} />
  {/* <Header /> */}

            <main>
              <div class="mx-auto">
                <div class="px-0 " style={{ background: "black" }}>
                  <div class="px-0 mx-auto">
                    <div class="flex flex-row content-center self-center bg-blue">
                      <div style={{ height: "50px", width: "60px", overflow: "hidden" }} className="ml-6">
                        <img style={{ objectFit: "fill" }} className="w-full" src={process.env.PUBLIC_URL + '/dashboardlogo.png'} alt="Login" />
                      </div>
                      <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-2xl tracking-wider ml-4  text-blue-900 font-bold uppercase justify-center self-center ">Backtrack</span>
                    </div>
                  </div>
                </div>
              </div>
              <section style={{ fontFamily: "Roboto !important" }} class="m-2 bg-gray-700">
                <div class=" px-4 py-2 mx-auto">
                  <div class="text-white mb-2">
                    <span style={{ fontFamily: "Roboto", color: "white" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Bot Configuration</span>
                  </div>
                  <div class="w-full row">
                    <div class="flex flex-wrap bg-white">
                      <div class="row p-4 rounded-2xl bg-blend-color bg-white">
                        <div class="flex gap-20 flex-wrap bg-white">
                          <div>
                            <p style={{ fontFamily: "Roboto,arial,sans-serif" }} className="font-bold">Algorithm Selection</p>
                            <Select  className="m-4" styles={{
                              
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'bg-slate-300',
                                width: '200px'
                              }),
                            }}
                              value={this.state.rlalgorithm_options_select_default} style={{ width: "300px" }} options={this.state.rlalgorithm_options_select} onChange={this.onAlgorithmChanged} />


                            <p style={{ fontFamily: "Roboto,arial,sans-serif" }} className="font-bold">Trading Pair</p>
                            <Select width="400px" className="m-4" styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'bg-slate-300',
                              }),
                            }}
                              value={this.state.model_options_select_default} style={{ width: "300px" }} options={this.state.model_options_select} onChange={this.onPairChanged} />
                          </div>
                          <div>
                            <div className="grid gap-1 grid-cols-1 grid-rows-3">
                              <button style={{ fontFamily: "Roboto,arial,sans-serif" }} disabled={!this.state.runBacktestBtnVis} className={"w-full h-10 md:w-auto font-medium text-white " + (!this.state.runBacktestBtnVis ? "bg-gray-600 text-gray-300 " : "bg-indigo-500 hover:bg-indigo-600 ") + "rounded transition duration-200 w-8 py-2 px-5 mt-4"} onClick={this.runBacktest}>
                                RUN BACKTEST
                              </button>
                              <button style={{ fontFamily: "Roboto,arial,sans-serif" }} class=" w-full md:w-auto  h-10 font-medium text-white bg-gray-500 hover:bg-gray-600 rounded transition duration-200 w-8 py-2 px-5 mt-4" onClick={this.useModel}>
                                USE
                              </button>
                              <button style={{ fontFamily: "Roboto,arial,sans-serif" }} class=" w-full md:w-auto  h-10 font-medium text-white bg-gray-500 hover:bg-gray-600 rounded transition duration-200 w-8 py-2 px-5 mt-4" onClick={this.refreshData}>
                                REFRESH
                              </button>
                            </div>
                          </div>
                          <div>
                            <p style={{ fontFamily: "Roboto,arial,sans-serif" }} className="font-bold mt-2 mb-4">Data Points</p>
                            <p>{this.state.noPoints}</p>
                            <input onChange={(x) => { this.setState({ ...this.state, noPoints: x.target.value }) }} id="large-range" type="range" value={this.state.noPoints} min={100} max={1200} className="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700 mt-4"></input>
                          </div>
                          <div  >
                            <p style={{ fontFamily: "Roboto,arial,sans-serif" }} className="font-bold mt-2 mb-4">Take Profit</p>
                            <p className="mt-4">{this.state.takeProfit == 0 ? "Auto" : this.state.takeProfit + "%"}</p>
                            <input id="large-range" step={0.01} onChange={(x) => { this.setState({ ...this.state, takeProfit: x.target.value }) }} min={0} max={5} value={this.state.takeProfit} type="range" className="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700 mt-4"></input>
                          </div>
                          <div>
                            <p style={{ fontFamily: "Roboto,arial,sans-serif" }} className="font-bold mt-2 mb-4">Stop Loss</p>
                            <p>{this.state.stopLoss == 0 ? "Disabled" : this.state.stopLoss + "%"}</p>
                            <input id="large-range" step={0.01} onChange={(x) => { this.setState({ ...this.state, stopLoss: x.target.value }) }} min={0} max={5} value={this.state.stopLoss} type="range" className="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700 mt-4"></input>
                          </div>
                          <div>


                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-full row">
                      <div style={{ width: "100%" }} className="grid grid-cols-3 bg-white shadow-lg rounded-sm border border-gray-200">
                        <div class="col-span-2">
                          <div style={{ position: "sticky" }} className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
                            <Chart
                              layout={this.state.chart.layout}
                              watermark={this.state.chart.watermark}
                              crosshair={this.state.chart.crosshair}
                              grid={this.state.chart.grid}
                              localization={this.state.chart.localization}
                              timeScale={this.state.chart.timeScale}
                              height={600}

                            >
                              <LineSeries ref={this.series1}
                                lineColor='gray' data={this.state.data} markers={this.state.markers} reactive={true} />
                            </Chart>
                            <p className="p-4 bg-black text-white text-bold">
                              Run on: {this.state.runOn} (hours). &emsp; Total Profilt: <NumberFormat decimalScale={2} value={(this.state.baseOrderLimit / 100) * this.state.fundsAllocated * this.state.pctRet / 100} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <span>{formattedValue}</span>} />
                              &emsp;( {(this.state.pctRet).toFixed(2)} %)
                              {/* &emsp;( {(this.state.totalProfit / this.state.fundsAllocated / this.state.baseOrderLimit * 10000).toFixed(2) } %) */}
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <div className="p-0">
                            {/* Table */}
                            <div className="overflow-x-auto">
                              <table className="table-auto w-full">
                                {/* Table header */}
                                <thead className="flex w-full text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                                  <tr className="text-xs text-white bg-gray-800 text-left flex w-full">
                                    <th style={{ width: '33.3%' }} className="p-4 font-medium text-center font-bold">
                                      <div className="font-semibold text-left">Time</div>
                                    </th>
                                    <th style={{ width: '33.3%' }} className="p-4 font-medium text-center font-bold">
                                      <div className="font-semibold text-center">Action</div>
                                    </th>
                                    <th style={{ width: '33.3%' }} className="p-4 font-medium text-center font-bold">
                                      <div className="font-semibold text-center">Profit/Loss</div>
                                    </th>
                                  </tr>
                                </thead>
                                {/* Table body */}
                                <tbody className="flex flex-col items-center overflow-y-auto" style={{maxHeight:"35rem"}}>
                                  {/* Row */}
                                  {this.state.actionList.map((item, index) => (
                                    <tr key={index} className="flex w-full">
                                      <td style={{ width: '33.3%' }} className="p-4 font-medium text-center mt-2">
                                        <div className="flex items-center">
                                          <div className="text-gray-800">{item[0]}</div>
                                        </div>
                                      </td>
                                      <td style={{ width: '33.3%' }} className="p-4 font-medium text-center mt-2">
                                        <span className={`inline-block py-2 px-4 text-white ${item[1] === "buy" ? "bg-green-500" : (item[1] === "sell" ? "bg-red-500" : "bg-gray-500")} text-center rounded-full p-4`}>
                                          {item[1] === "buy" ? (
                                            <span>
                                              <FaArrowAltCircleUp className="inline mr-4"></FaArrowAltCircleUp>BUY
                                            </span>
                                          ) : (
                                            item[1] === "sell" ? (
                                              <span>
                                                <FaArrowAltCircleDown className="inline mr-4"></FaArrowAltCircleDown>SELL
                                              </span>
                                            ) : (
                                              <span>
                                                <FaPauseCircle className="inline mr-4"></FaPauseCircle>NO ACTION
                                              </span>
                                            )
                                          )}
                                        </span>
                                      </td>
                                      <td style={{ width: '33.3%' }} className="p-4 font-medium text-center mt-2">
                                        <div className="text-center">{item[4]}%</div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <Banner />
          </div>
        </div>
        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      Refreshing data please wait ...
                    </h3>
                  </div>
                  {/*body*/}
                  {/* <div className="relative p-6 flex-auto">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                     Please wait.
                  </p>
                </div> */}
                  {/*footer*/}

                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>


    );
  }
}

export default RLBot;
