import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { checkAuthentication } from '../serives/backendService';

export default function Login({ setToken }) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const usernameRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await checkAuthentication(username, password);
      const token = res.status;
      setToken(token);
      if (!token) {
        setLoginSuccess(false);
        setErrorMessage('Incorrect login details. Please try again.');
        setUserName('');
        setPassword('');
        if (usernameRef.current) {
          usernameRef.current.focus();
        }
      } else {
        setLoginSuccess(true);
        setErrorMessage('');
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      setLoginSuccess(false);
      setErrorMessage('An error occurred. Please try again.');
      setUserName('');
      setPassword('');
      if (usernameRef.current) {
        usernameRef.current.focus();
      }
    }
  };

  return (
    <div>
      <div  className="w-full h-screen flex items-center justify-center">
        <form style={{maxWidth: "500px"}} className="w-full md:w-1/3 bg-white rounded-lg shadow-md" onSubmit={handleSubmit}>
          <div className="flex font-bold justify-left ">
            <img className="h-10 w-10 mb-4" src={process.env.PUBLIC_URL + '/login.png'} alt="Login" />
            
          </div>
          <div className="flex font-bold justify-center ">
           <p className="flex font-bold text-2xl mb-4">Sign In</p>
          </div>
          <div className="px-12 pb-10">
            <div className="w-full mb-2">
              <div className="flex items-center">
                <i className="ml-3 fill-current text-gray-400 text-xs z-10 fas fa-user"></i>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                  ref={usernameRef}
                  className="-mx-6 px-8  w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                />
              </div>
            </div>
            <div className="w-full mb-2">
              <div className="flex items-center">
                <i className="ml-3 fill-current text-gray-400 text-xs z-10 fas fa-lock"></i>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="-mx-6 px-8 w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                />
              </div>
            </div>
            {loginSuccess ? null : (
              <div className="w-full mt-4">
                <div className="flex items-center mt-2">
                  <p className="text-red-400">{errorMessage}</p>
                </div>
              </div>
            )}

            <div className="w-full">
              <button
                type="submit"
                style={{ marginTop: '40px', marginLeft: '-10px', fontWeight: 'bold' }}
                className="w-full py-2 rounded-md bg-blue-600 text-gray-100 focus:outline-none"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
